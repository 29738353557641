// 拼团商品 编辑按钮显示
export function editBtnStatusCoupon(status) {
    return status == 2 || status == 4 || (superManage() && status != 1);
}

// 优惠券 编辑按钮显示
export function editBtnStatus(row) {
    return ((row.status == 2 || row.status == 0 || row.status == 4) && row.used != 1) || (superManage() && !downStatus(row));
}
// 优惠券 下架按钮显示
export function downStatus(row) {
    return ((row.status == 1 && (row.purpose == 1 || row.purpose == 7)) || (row.status == 8 && row.purpose == 1)) && row.purpose != 14;
}

export function superManage() {
    // return false;
    return $.cookie('role_id') == 1;
}

export function showERP(mall_id) {
    return ['2427', '2428', '2379'].indexOf(mall_id) != -1;
}

export function isUniquePage() {
    return  localStorage.getItem('mall_edition') == 1; // 1:集团集团管理页，2集团商场管理页
}

// 华联
export function isPaidMember() {
    const company_id = localStorage.getItem('company_id');
    // 12165 及购集团
    // 12056 北纬国际
    // 12296 闪购集团
    // 12158 北京华联集团
    // 12136 保利商业
    // 12191 保利商业 测试环境
    // return company_id == '12158' || company_id == '12056' || company_id == '12165' || company_id == '12181';
    return company_id == '12158' || company_id == '12165' || company_id == '12056' || company_id == '12191' || company_id == '12136' || company_id == '12296';
}

// 保利优惠券定制化报表
export function isBaoLi() {
    // const company_id = localStorage.getItem('company_id');
    // // const mallId = $.cookie('mall_id');
    // return company_id == 12056 || company_id == 12136 || company_id == 12165;
    return +localStorage.getItem('attribution') === 6;
}
