export const getRouteList = () => {
    let value = [
        {
            routes: ['marketingdata', 'groupmarketingdata', 'memberdata', 'groupmemberdata'],
            icon: 'icon-shujugailan-xian-yuan',
        },
        {
            routes: ['accountset'], //账号设置
            icon: 'icon-zhanghaoshezhi-xian-yuan',
        },
        {
            routes: ['role', 'roleedit', 'roledetail'], // 角色管理
            icon: 'icon-jiaoseguanli-xian-yuan',
        },
        {
            routes: ['account', 'neweditaccount', 'accountdetail'], //账号管理
            icon: 'icon-zhanghaoguanli-xian-yuan',
        },
        {
            routes: ['coupon', 'addcoupon', 'coupondetail', 'auditShotDetail', 'auditDetail', 'couponAudit', 'couponreport', 'couponreceive', 'valucoupondetail', 'datadetail', 'coupondata'], // 闪购优惠券
            icon: 'icon-shangouyouhuiquan-xian-yuan',
        },
        {
            routes: ['wxcoupon', 'ysfcoupon'], // 外部优惠券
            icon: 'icon-weixindaijinquan-xian-yuan',
        },
        {
            routes: ['goodsaccount', 'goodsaccountdetail'], // 传统分账账单
            icon: 'icon-chuantongfenzhangzhangdan-xian-yuan',
        },
        {
            routes: ['fareaccount', 'fareaccountdetail'], // 运费账单
            icon: 'icon-chuantongfenzhangyunfeizhangdan-xian-yuan',
        },
        {
            routes: ['splitaccount', 'splitaccountdetail'], // 进阶分账账单
            icon: 'icon-jinjiefenzhangzhangdan-xian-yuan',
        },
        {
            routes: ['quickshopping', 'quickshoppingadd', 'quickshoppingdetail', 'quickshoppingreport', 'couponpackageeidt', 'couponpackagedetail', 'couponPackageAuditShotDetail', 'couponPackageAuditDetail', 'couponPackageAudit'], // 商品管理
            icon: 'icon-shangoushangpin-xian-yuan',
        },
        {
            routes: ['valuablecouponadd', 'valuablecoupondetail', 'valuableAuditShotDetail', 'valuableAuditDetail', 'valuableAudit'], // 闪购优惠券
            icon: 'icon-a-shangchangshezhi-xian-yuan3x',
        },
        {
            routes: ['fightgroup', 'addgroup', 'fightreport', 'groupdetail', 'valuablecouponaddtwo', 'valuablecouponreport', 'couponpackageeidtgroup', 'couponpackagedetailgroup'], // 拼团商品
            icon: 'icon-pintuanshangpin-xian-yuan',
        },
        {
            routes: ['viewregisterconfig'], // 注册与个人信息
            icon: 'icon-zhuceyugerenxinxi-xian-yuan',
        },
        {
            routes: ['pointset'], // 积分规则
            icon: 'icon-jifenguize-xian-yuan',
        },
        {
            routes: ['memberLevel', 'memberLevelupdate'], // 会员等级设置
            icon: 'icon-huiyuandengjishezhi-xian-yuan',
        },
        {
            routes: ['memberSign'], // 签到设置
            icon: 'icon-qiandaoshezhi-xian-yuan',
        },
        {
            routes: [
                'market',
                'game',
                'balldetail',
                'addball',
                'gamereport',
                'turntable',
                'tabledetail',
                'newgamelist',
                'newgameadd',
                'newgamedetail',
                'newgamereport',
                'socialfission',
                'addfission',
                'fissiondetail',
                'fissionreport',
                'assistance',
                'assistanceAdd',
                'assistanceReport',
                'assistanceDetail',
                'inviteregister',
                'inviteregisterdetail',
                'inviteregisterreport',
                'inviteregisterrAdd',
                'smile',
                'interact',
                'interactmanage',
                'smiledetail',
                'activityRegistration',
                'activityRegistrationDetail',
                'activityRegistrationEdit',
                'activityRegistrationReport',
            ], // 营销活动
            icon: 'icon-yingxiaohuodong-xian-yuan',
        },
        {
            routes: ['member', 'membermanage'], // 会员列表
            icon: 'icon-a-huiyuanliebiao-xian-yuan2',
        },
        {
            routes: ['membertag', 'membertaglist'], // 会员标签
            icon: 'icon-huiyuanbiaoqian-xian-yuan',
        },
        {
            routes: ['memberclustering', 'memberclusteringadd', 'memberclusteringdetail'], // 会员分群
            icon: 'icon-huiyuanfenqun-xian-yuan',
        },
        {
            routes: ['grouporderlist', 'refunddetail', 'grouporderdetail', 'cashorder', 'cashorderdetail', 'cashrefunddetail'], // 商城订单
            icon: 'icon-xianshangshangchengdingdan-xian-yuan',
        },
        {
            routes: ['cashorder', 'cashorderdetail'], // 小程序收银订单
            icon: 'icon-xiaochengxushouyindingdan-xian-yuan',
        },

        {
            routes: ['feedback', 'feedbackdetail'], // 会员反馈
            icon: 'icon-huiyuanfankui-xian-yuan',
        },
        {
            routes: ['ticketsverify', 'ticketdetail'], // 小票审核
            icon: 'icon-xiaopiaoshenhe-xian-yuan',
        },
        {
            routes: ['liveTelecast', 'goodsoverview'], // 小程序直播
            icon: 'icon-xiaochengxuzhibo-xian-yuan',
        },
        {
            routes: ['parkforms'], // 停车报表
            icon: 'icon-tingchebaobiao-xian-yuan',
        },
        {
            routes: ['parkcoupon', 'parkaddcoupon', 'parkcoupondetail', 'parkcouponreport', 'ParkValuableCouponAdd', 'ParkValuableCouponDetail', 'ParkValuableCouponReport'], // 停车券
            icon: 'icon-tingchequan-xian-yuan',
        },
        {
            routes: ['parkset', 'parkedit'], // 停车设置
            icon: 'icon-tingcheshezhi-xian-yuan',
        },
        {
            routes: ['itemlist', 'itemdetail', 'itemadd', 'itemreport'], // 积分商城
            icon: 'icon-jifenshangpin-xian-yuan',
        },
        {
            routes: ['orderlist', 'orderdetail'], // 兑换订单
            icon: 'icon-duihuandingdan-xian-yuan',
        },
        {
            routes: ['sendcoupon', 'sendcouponadd', 'sendcouponadds', 'sendcouponsceneedit', 'sendcouponreport', 'sendplandetail'], // 定向发券
            icon: 'icon-dingxiangfaquan-xian-yuan',
        },
        {
            routes: ['advertisement', 'advertisementDetail', 'brandPromotion'], // 智能广告
            icon: '',
        },
        {
            routes: [
                'appletsmanage',
                'seachkeyword',
                'thememanage',
                'category',
                'imagemanage',
                'barmanage',
                'modulemanage',
                'modulemanage',
                'applyimagemanage',
                'applybarmanage',
                'applycardmanage',
                'brandmanage',
                'pointmanage',
                'activityRegistrationManage',
                'CustomNavigation',
            ], // 小程序管理
            icon: 'icon-xiaochengxushouyeguanli-xian-yuan',
        },
        {
            routes: ['shelfmanage'], //  闪购商品管理
            icon: 'icon-shangoushangpinhuojia-xian-yuan',
        },
        {
            routes: ['groupmanage'], //  拼团商品管理
            icon: 'icon-pintuanshangpinhuojia-xian-yuan',
        },
        {
            routes: ['valuablemanagement'], //  有价券专区
            icon: 'icon-youjiaquanquanjia-xian-yuan',
        },
        {
            routes: ['cardmanage'], //  免费券专区
            icon: 'icon-mianfeiquanquanjia-xian-yuan',
        },
        {
            routes: ['itemarea', 'itemareaadd'], //  闪购专区
            icon: 'icon-shangouzhuanqu-xian-yuan',
        },
        {
            routes: ['otherSettings'], // 其他设置
            icon: 'icon-qitashezhi-xian-yuan',
        },
        {
            routes: ['paymentPrompt', 'quickPointPrompt'], // 支付页提示、无感积分开通提示
            icon: 'icon-a-zhifuyetishi-xian-yuan3x',
        },
        {
            routes: ['privacyAgreement'], // 用户协议及隐私协议
            icon: 'icon-a-yonghuxieyijiyinsixieyi-xian-yuan3x',
        },
        {
            routes: ['itemSellInfo'], // 商品购买情况显示
            icon: 'icon-a-shangpingoumaiqingkuang-xian-yuanbeifen3x',
        },
        {
            routes: ['couponReceiveInfo'], // 优惠券领取情况显示
            icon: 'icon-a-youhuiquanlingquqingkuang-xian-yuan3x',
        },
        {
            routes: ['integral'], // 积分记录
            icon: 'icon-jifenjilu-xian-yuan',
        },
        {
            routes: ['consume'], // 消费记录
            icon: 'icon-xiaofeijilu-xian-yuan',
        },
        {
            routes: ['brandStreet'], // 品牌街
            icon: 'icon-pinpaijie-xian-yuan',
        },
        {
            routes: ['cancelmember'], // 注销记录
            icon: 'icon-zhuxiaojilu-xian-yuan',
        },
        {
            routes: ['customreport'], // 定制化报表
            icon: 'icon-dingzhihuabaobiao-xian-yuan',
        },
        {
            routes: ['transactionpay'], // 交易与支付管理
            icon: 'icon-jiaoyiyuzhifuguanli-xian-yuan',
        },
        {
            routes: ['shopregistercode'], // 店铺注册码
            icon: 'icon-dianpuzhucema-xian-yuan',
        },
        {
            routes: ['downloadcenter'], // 下载中心
            icon: 'icon-xiazaizhongxin-xian-yuan',
        },
        {
            routes: ['setmall', 'setmalldetail', 'setShoppingCenter', 'editShoppingCenter'], // 商场设置
            icon: 'icon-a-shangchangshezhi-xian-yuan3x',
        },
        {
            routes: ['setmallreception', 'setmallreceptionedit'], // 服务台设置
            icon: 'icon-a-fuwutaishezhi-xian-yuan3x',
        },
        {
            routes: ['newshopset'], // 商铺管理
            icon: 'icon-shangpuguanli-xian-yuan',
        },
        {
            routes: ['industryManagement'], // 行业管理
            icon: 'icon-hangyeguanli-xian-yuan',
        },
        {
            routes: ['Recommend', 'RecommendAdd', 'RecommendCheck'], // 种草
            icon: 'icon-zhongcaobiji-xian-yuan',
        },
        {
            routes: ['pointWarningDetail', 'pointWarningEdit'], // 预警配置
            icon: 'icon-yujingpeizhi-xian-yuan',
        },
        {
            routes: ['pointWarningRecord'], // 预警记录
            icon: 'icon-yujingjilu-xian-yuan',
        },
        {
            routes: ['BlackList'], // 黑名单
            icon: 'icon-heimingdanliebiao-xian-yuan',
        },
        {
            routes: ['NotificationCenter'], // 通知中心
            icon: 'icon-tongzhizhongxin-xian-yuan',
        },
        {
            routes: ['Questionnaire', 'QuestionnaireEdit', 'QuestionnaireDetail', 'QuestionnaireReport'], // 问卷调查
            icon: 'icon-wenjuantiaocha-xian-yuan',
        },
        {
            routes: ['loginSetting'], // 登陆设置
            icon: 'icon-denglushezhi-xian-yuan',
        },
        {
            routes: ['ocrTicketVerifyList', 'ocrTicketVerifyEdit', 'ocrTicketVerifyTest'], // OCR模板
            icon: 'icon-OCRmobanguanli-xian-yuan',
        },
        {
            routes: ['ocrTrafficManagement'], // OCR流量管理
            icon: 'icon-OCRliuliangguanli-xian-yuan',
        },
        {
            routes: ['addThirdCoupons', 'thirdCouponDetail'], // 添加第三方卡券 查看第三方卡券
            icon: 'icon-disanfangkaquan-xian-yuan',
        },
        {
            routes: ['memberdata', 'groupmemberdata'], // bi 会员与访问数据
            icon: 'icon-huiyuanyufangwenshuju-xian-yuan',
        },
        {
            routes: ['biOrderData'], // bi 订单数据
            icon: 'icon-dingdanshuju-xian-yuan1',
        },
        {
            routes: ['biMaterialData'], // bi 营销素材数据
            icon: 'icon-yingxiaosucaishuju-xian-yuan1',
        },
        {
            routes: ['BiInteractData'], // bi 互动数据
            icon: 'icon-hudongshuju-xian-yuan1',
        },
        {
            routes: ['BiPointsData'], // bi 积分数据
            icon: 'icon-jifenshuju-xian-yuan1',
        },
        {
            routes: ['biIndustriesStores'], // bi 行业与店铺数据
            icon: 'icon-hangyeyudianpushuju-xian-yuan1',
        },
        {
            routes: ['biParkData'], // bi 停车数据
            icon: 'icon-tingcheshuju-xian-yuan1',
        },
        {
            routes: ['biGroupRank'], // bi 集团对比排名
            icon: 'icon-jituanpaiming-xian-yuan1',
        },
        {
            routes: ['paidManagement', 'paidAgreement', 'paidPurchaseHistory', 'PaidRightsSettingDetail', 'PaidRightsSettingEdit', 'LandingPageDetail', 'LandingPageEdit', 'PaidSettingDetail', 'PaidSettingEdit', 'SellingSettingDetail', 'SellingSettingEdit'], // 付费会员管理 付费会员协议 付费会员购买记录
            icon: 'icon-fufeihuiyuanguanli-xian-yuan1',
        },
        {
            routes: ['channelsLink', 'channelsLinkDetail', 'ChannelsLinkAdd'], // 渠道二维码/链接
            icon: 'icon-qudaoerweima-xian-yuan',
        },
        {
            routes: ['redeemCodeList', 'redeemCodeAdd', 'redeemCodeReport'], // 兑换码管理
            icon: 'icon-duihuanmaguanli-xian-yuan',
        },
        {
            routes: ['exchangeRecord'], // 兑换记录
            icon: 'icon-duihuanjilu-xian-yuan',
        },
        {
            routes: ['cancellationRecord'], // 作废记录
            icon: 'icon-zuofeijilu-xian-yuan',
        },
        {
            routes: ['MemberManualTag', 'MemberManualTagEdit', 'TagGroupDetail'], // 手动标签
            icon: 'icon-shoudongbiaoqian-xian-yuan1',
        },{
            routes: ['MemberAutoTag', 'MemberAutoTagEdit', 'AutoTagGroupDetail'], // 自动标签
            icon: 'icon-zidongbiaoqian-xian-yuan1',
        },
        {
            routes: ['MassTexting', 'MassTextingEdit'], // 短信--群发列表
            icon: 'icon-duanxinqunfa-xian-yuan',
        },
        {
            routes: ['SendingRecord'], // 短信--发送记录
            icon: 'icon-fasongjilu-xian-yuan',
        },
        {
            routes: ['TemplateManagement'], // 短信--模板管理
            icon: 'icon-mobanguanli-xian-yuan',
        },
        {
            routes: ['smsEdit','templateDetail'], // 短信--模板管理--新建/详情
            icon: 'icon-duanxinqunfa-xian-yuan',
        },
        {
            routes: ['FunctionConfig'], // ai-功能配置
            icon: 'icon-gongnengpeizhi-xian-yuan',
        },
        {
            routes: ['SessionLog', 'SessionLogDetail'], // ai-对话记录
            icon: 'icon-duihuajilu-xian-yuan',
        },
        {
            routes: ['ResourceManage'], // ai-资源与用量管理
            icon: 'icon-ziyuanyuyongliangguanli-xian-yuan',
        },
        {
            routes: ['ConsumerRelevancyAnalysis', 'RFMModel', 'RModelEdit', 'FMModelEdit', 'RFMModelDetail'], // ai-资源与用量管理
            icon: 'icon-zidongbiaoqian-xian-yuan',
        }

    ];
    return value;
};
