export const getSearchFilters = (type) => {
    const filters = {
        redeemCodeList: [
            { value: 'title', label: '名称' },
            { value: 'cdkey_batch_id', label: '批次ID' },
            { value: 'object_name', label: '兑换码内容' },
        ],
        exchangeRecord: [
            { value: 'mobile', label: '会员手机号' },
            { value: 'cdkey_batch_id', label: '批次ID' },
            { value: 'source', label: '兑换码' },
            { value: 'cdkey_batch_title', label: '名称' },
        ],
        cancellationRecord: [
            { value: 'cdkey_batch_title', label: '名称' },
            { value: 'cdkey_batch_id', label: '批次ID' },
            { value: 'source', label: '兑换码' },
            { value: 'invalid_by', label: '作废操作人' },
        ],
        redeemCodeReport__exchange: [
            { value: 'mobile', label: '会员手机号' },
            { value: 'source', label: '兑换码' },
        ],
        redeemCodeReport__invalid: [{ value: 'source', label: '兑换码' }],
        coupon: [
            { value: 'name_keyword', label: '优惠券名称' },
            { value: 'id_keyword', label: '优惠券ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        member: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        integral: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        consume: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'order_sn_keyword', label: '订单号' },
        ],
        memberClustering: [{ value: 'name_keyword', label: '群组名称' }],
        blackList: [
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        cancelMember: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        wxCoupon: [
            { value: 'name_keyword', label: '代金券名称' },
            { value: 'third_id_keyword', label: '批次ID' },
        ],
        ysfCoupon: [
            { value: 'name_keyword', label: '优惠券名称' },
            { value: 'third_id_keyword', label: '活动编号' },
        ],
        goodsActivity: [
            { value: 'name_keyword', label: '商品名称' },
            { value: 'id_keyword', label: '商品ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        itemList: [
            { value: 'name_keyword', label: '商品名称' },
            { value: 'id_keyword', label: '商品ID' },
        ],
        orderList: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'item_id_keyword', label: '商品ID' },
            { value: 'item_name_keyword', label: '商品名称' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        market: [
            { value: 'name_keyword', label: '活动名称' },
            { value: 'id_keyword', label: '活动ID' },
        ],

        // TODO zll

        // 营销宝---进阶分账资金流水
        interact: [
            { value: 'name_keyword', label: '活动名称' },
            { value: 'id_keyword', label: '活动ID' },
        ],
        activityRegistration: [
            { value: 'name_keyword', label: '活动名称' },
            { value: 'id_keyword', label: '活动ID' },
        ],
        brandStreet: [
            { value: 'shop.name', label: '店铺名称' },
            { value: 'shop.id', label: '店铺ID' },
            { value: 'content', label: '宣传语' },
        ],
        recommend: [
            { value: 'shop_name_keyword', label: '店铺名称' },
            { value: 'id_keyword', label: '笔记ID' },
            { value: 'content_keyword', label: '笔记内容' },
        ],
        sendCoupon: [
            { value: 'name_keyword', label: '发券计划名称' },
            { value: 'id_keyword', label: '发券计划ID' },
        ],
        shopRegisterCode: [
            { value: 'shop_name_keyword', label: '店铺名称' },
            { value: 'shop_id_keyword', label: '店铺ID' },
        ],
        liveTelecast: [
            { value: 'name_keyword', label: '直播间名称' },
            { value: 'id_keyword', label: '直播ID' },
            { value: 'anchor_name_keyword', label: '主播名称' },
        ],
        channels: [
            { value: 'name_keyword', label: '对象名称' },
            { value: 'batch_id_keyword', label: '批次ID' },
        ],
        groupOrder: [
            //线上商城订单（不包括退款）
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'item_id_keyword', label: '商品ID' },
            { value: 'item_name_keyword', label: '商品名称' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        groupOrderRefund: [
            // 线上商城退款订单
            { value: 'sn_keyword', label: '退款订单号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'item_id_keyword', label: '商品ID' },
            { value: 'item_name_keyword', label: '商品名称' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        cashOrder: [
            //小程序收银订单（不包括退款）
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'shop_name_keyword', label: '消费店铺' },
        ],
        cashOrderRefund: [
            //小程序收银退款订单
            { value: 'sn_keyword', label: '退款订单号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'shop_name_keyword', label: '退款店铺' },
        ],
        goodsAccount: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'sn_keyword', label: '分账单号' },
            { value: 'item_id_keyword', label: '商品ID' },
            { value: 'item_name_keyword', label: '商品名称' },
        ],
        mallGoodsAccount: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
            { value: 'item_name_keyword', label: '商场券名称' },
        ],
        fareAccount: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'account_keyword', label: '收款账户' },
        ],
        capitalFlowTradition: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'sn_keyword', label: '资金流水单号' },
            { value: 'transaction_source_keyword', label: '业务凭证单号' },
        ],
        splitAccount: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'sn_keyword', label: '分账单号' },
            { value: 'item_id_keyword', label: '商品ID' },
            { value: 'item_name_keyword', label: '商品名称' },
        ],
        mallSplitAccount: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'transaction_id_keyword', label: '交易号' },
        ],
        capitalFlowMerchant: [
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'sn_keyword', label: '资金流水单号' },
        ],

        // TODO lishuyu
        newShopSet: [
            { value: 'shop_name_keyword', label: '店铺名称' },
            { value: 'shop_id_keyword', label: '店铺ID' },
        ],
        accountManageList: [
            { value: 'username_keyword', label: '账号名称' },
            { value: 'email_keyword', label: '登录账号' },
        ],
        roleManageList: [{ value: 'nickname_keyword', label: '角色名称' }],
        pointWarningList: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        transactionPay: [
            { value: 'shop_id_keyword', label: '店铺ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        activityRegistrationManage: [
            { value: 'name_keyword', label: '活动名称' },
            { value: 'id_keyword', label: '活动ID' },
        ],
        pointmanageList: [
            { value: 'name_keyword', label: '商品名称' },
            { value: 'id_keyword', label: '商品ID' },
        ],
        itemArea: [{ value: 'name_keyword', label: '专区名称' }],
        cardManage: [
            { value: 'name_keyword', label: '优惠券名称' },
            { value: 'id_keyword', label: '优惠券ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        valuableManagement: [
            { value: 'name_keyword', label: '优惠券名称' },
            { value: 'id_keyword', label: '优惠券ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        groupManage: [
            { value: 'name_keyword', label: '商品名称' },
            { value: 'id_keyword', label: '商品ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        shelfManage: [
            { value: 'name_keyword', label: '商品名称' },
            { value: 'id_keyword', label: '商品ID' },
            { value: 'shop_name_keyword', label: '店铺名称' },
        ],
        parkForms: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'order_sn_keyword', label: '订单号' },
            { value: 'name_keyword', label: '缴费车牌' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        parkCouponList: [
            { value: 'name_keyword', label: '停车券名称' },
            { value: 'id_keyword', label: '停车券ID' },
        ],
        questionnaire: [
            { value: 'name_keyword', label: '问卷名称' },
            { value: 'id_keyword', label: '问卷ID' },
        ],
        feedback: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'id_keyword', label: '问题ID' },
            { value: 'content_keyword', label: '问题描述' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
        ],
        ticketVerify: [
            { value: 'mobile_keyword', label: '会员手机号' },
            { value: 'sn_keyword', label: '审核编号' },
            { value: 'source_keyword', label: '订单号' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'member_sn_keyword', label: '会员卡号' },
            { value: 'verified_by_keyword', label: '审核人' },
        ],
        OcrTicketVerify: [
            { value: 'shop_name_keyword', label: '店铺名称' },
            { value: 'shop_id_keyword', label: '店铺ID' },
        ],
        downloadCenter: [{ value: 'name_keyword', label: '任务名称' }],
        manualTag: [{ value: 'name', label: '标签组名称' }],
        tagDetail: [
            { value: 'mobile', label: '手机号' },
            { value: 'sn', label: '卡号' },
            { value: 'nickname', label: '昵称' },
        ],
        // 短信群发
        massTextingList: [
            { value: 'name_keyword', label: '任务名称' },
            { value: 'id_keyword', label: '任务ID' },
            { value: 'created_by_keyword', label: '创建者' },
        ],
        smsTemplateManagement: [
            { value: 'name_keyword', label: '模版名称' },
            { value: 'id_keyword', label: '模版ID' },
            { value: 'created_by_keyword', label: '创建者' },
        ],
        // 短信发送记录
        smsSendingRecords: [{ value: 'mobile_keyword', label: '手机号码' }],
        textingDetail: [{ value: 'mobile_keyword', label: '手机号码' }],
        aiSessionLog: [
            { value: 'session_id_keyword', label: '对话ID' },
            { value: 'nickname_keyword', label: '会员昵称' },
            { value: 'mobile_keyword', label: '会员手机号' },
        ],
        shoppingCenterList: [
            { value: 'name_keyword', label: '购物中心名称' },
            { value: 'id_keyword', label: '购物中心ID' },
        ],
        mallCouponAudit: [
            { value: 'object_name_keyword', label: '名称' },
            { value: 'source_keyword', label: '审核编号' },
            { value: 'object_id_keyword', label: '对象ID' },
        ], // 商场券审核
        RFMDetail: [
            { value: 'mobile', label: '手机号' },
            { value: 'sn', label: '卡号' },
            { value: 'nickname', label: '昵称' },
        ],
    };
    return filters[type];
};
