// 财务审核中心
const parent = 'financialAuditCenter';

export default [
    // 商场圈审核列表
    {
        path: '/mallCouponAudit',
        name: 'mallCouponAudit',
        meta: { parent, name: 'name' },
        component: () => import('@/views/MallCouponAudit/index'),
    },
    // 优惠券审核（保利）
    {
        path: '/couponAudit',
        name: 'couponAudit',
        meta: { parent, name: 'mallCouponAudit' },
        component: () => import('@/components/Coupon/Coupondetail'),
    },
    // 优惠券审核详情（保利）
    {
        path: '/auditDetail',
        name: 'auditDetail',
        meta: { parent, name: 'mallCouponAudit' },
        component: () => import('@/components/Coupon/Coupondetail'),
    },
    //有价券审核详情
    {
        path: "/valuableAuditDetail",
        name: "valuableAuditDetail",
        meta: { parent, name: 'mallCouponAudit'},
        component: () => import("@/components/ValuableCoupon/ValuableCouponDetail"),
    },
    //有价券审核
    {
        path: "/valuableAudit",
        name: "valuableAudit",
        meta: { parent, name: 'mallCouponAudit'},
        component: () => import("@/components/ValuableCoupon/ValuableCouponDetail"),
    },
    // 有价券包审核详情
    {
        path: "/couponPackageAuditDetail",
        name: "couponPackageAuditDetail",
        meta: { parent, name: 'mallCouponAudit'},
        component: () => import("@/views/CouponPackage/detail"),
    },
    // 有价券包审核
    {
        path: "/couponPackageAudit",
        name: "couponPackageAudit",
        meta: { parent, name: 'mallCouponAudit'},
        component: () => import("@/views/CouponPackage/detail"),
    },
];
