import Vue from 'vue';
import VueRouter from 'vue-router';
// 营销管理
import marketingData from './modules/marketingData';
import memberManage from './modules/memberManage';
import couponManage from './modules/couponManage';
import sendCoupon from './modules/sendCoupon';
import accountManage from './modules/accountManage';
import goodsAccount from '@/router/modules/goodsAccount';
import setMall from '@/router/modules/setMall';
import marketingMaterials from './modules/marketingMaterials';
import goodsManage from './modules/goodsManage';
import orderManage from './modules/orderManage';
import pointMall from './modules/pointMall';
import wechatManage from './modules/wechatManage';
import smartScreen from './modules/smartScreen';
import parkManage from './modules/parkManage';
import marketingConfig from './modules/marketingConfig';
import pointWarning from './modules/pointWarning';
import other from './modules/other';
import redeemCode from '@/router/modules/redeemCode';
import massTexting from '@/router/modules/massTexting';
import aiGuide from '@/router/modules/aiGuide';
import financialAuditCenter from '@/router/modules/financialAuditCenter';

// 基础配置
import baseConfig from './baseConfig';

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const Layout = () => import('@/Layout/index');

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/components/Login/Login'),
    },
    {
        path: '/findpass',
        name: 'findpass',
        component: () => import('@/components/Login/Findpass'),
    },
    // 修改密码页面（修改密码才能使用系统）
    {
        path: '/setNewPassword',
        name: 'setNewPassword',
        component: () => import('@/views/setNewPassword'),
    },
    {
        path: '/',
        component: Layout,
        redirect: '/marketingData',
        name: 'layout',
        children: [
            {
                path: 'groupindex',
                name: 'groupindex',
                component: () => import('@/views/GroupIndex'),
            },
            {
                path: 'contentindex',
                name: 'contentindex',
                component: () => import('@/views/GroupIndex'),
            },
            {
                path: '/integral',
                name: 'integral',
                meta: { title: ' 积分记录' },
                component: () => import('@/components/Member/Integral'),
            },
            {
                path: '/consume',
                name: 'consume',
                meta: { title: ' 消费记录' },
                component: () => import('@/components/Member/Consume'),
            },
            {
                path: '/brandStreet',
                name: 'brandStreet',
                meta: { title: '品牌街' },
                component: () => import('@/components/BrandStreet/BrandStreet'),
            },
            {
                path: '/recommend',
                name: 'Recommend',
                meta: { title: '种草笔记' },
                component: () => import('@/views/recommend'),
            },
            {
                path: '/recommend/add',
                name: 'RecommendAdd',
                meta: { title: '新增种草笔记', parent: 'Recommend' },
                component: () => import('@/views/recommend/add'),
            },
            {
                path: '/recommend/check',
                name: 'RecommendCheck',
                meta: { title: '查看种草笔记', parent: 'Recommend' },
                component: () => import('@/views/recommend/check'),
            },
            {
                path: '/cancelmember',
                name: 'cancelmember',
                meta: { title: '注销记录' },
                component: () => import('@/components/Member/CancelMember'),
            },
            //千人千面
            {
                path: '/thousand',
                name: 'thousand',
                component: () => import('@/components/Coupon/Thousand'),
            },
            {
                path: 'customReport',
                name: 'customreport',
                meta: { title: ' 定制化报表' },
                component: () => import('@/components/CustomReport/CustomReport'),
            },
            {
                path: '/transactionpay',
                name: 'transactionpay',
                meta: { title: ' 交易与支付管理' },
                component: () => import('@/components/TransactionPay/TransactionPay'),
            },
            // 渠道二维码/链接
            {
                path: '/channelsLink',
                name: 'channelsLink',
                component: () => import('@/views/ChannelsLink'),
            },
            // 新增渠道二维码/链接
            {
                path: '/channelsLinkAdd',
                name: 'ChannelsLinkAdd',
                meta: { parent: 'market', name: 'channelsLink' },
                component: () => import('@/views/ChannelsLink/newAdd'),
            },
            // 渠道二维码/链接详情
            {
                path: '/channelsLinkDetail',
                name: 'channelsLinkDetail',
                meta: { parent: 'market', name: 'channelsLink' },
                component: () => import('@/views/ChannelsLink/detail'),
            },
            // 店铺注册码
            {
                path: '/shopregistercode',
                name: 'shopregistercode',
                component: () => import('@/components/ShopRegisterCode/ShopRegisterCode'),
            },
            // 下载中心
            {
                path: '/downloadcenter',
                name: 'downloadcenter',
                component: () => import('@/components/DownloadCenter'),
            },
            // RFM模型
            {
                path: '/RFMModel',
                name: 'RFMModel',
                component: () => import('@/views/CustomizedCharts/RFMModel'),
                meta: {},
            },
            // R模型 编辑
            {
                path: '/RModelEdit',
                name: 'RModelEdit',
                component: () => import('@/views/CustomizedCharts/RFMModel/REdit'),
                meta: { parent: 'RFMModel', name: 'RFMModel' },
            },
            // FM模型 编辑
            {
                path: '/FMModelEdit',
                name: 'FMModelEdit',
                component: () => import('@/views/CustomizedCharts/RFMModel/FMEdit'),
                meta: { parent: 'RFMModel', name: 'RFMModel' },
            },
            // FM模型 详情
            {
                path: '/RFMModelDetail',
                name: 'RFMModelDetail',
                component: () => import('@/views/CustomizedCharts/RFMModel/Detail'),
                meta: { parent: 'RFMModel', name: 'RFMModel' },
            },
            {
                path: '/ConsumerRelevancyAnalysis',
                name: 'ConsumerRelevancyAnalysis',
                component: () => import('@/views/CustomizedCharts/ConsumerRelevancyAnalysis/index'),
                meta: { parent: 'RFMModel', name: 'name' },
            },
            ...marketingData, // BI分析
            ...memberManage, // 会员管理
            ...couponManage, // 优惠券管理
            ...sendCoupon, // 定向发券
            ...accountManage, // 账单管理
            ...goodsAccount, // 商品账单
            ...marketingMaterials, // 营销管理
            ...goodsManage, // 商品管理
            ...orderManage, // 订单管理
            ...pointMall, // 积分商场
            ...wechatManage, // 小程序管理
            ...smartScreen, // 智能屏幕
            ...parkManage, // 停车券管理
            ...marketingConfig, // 营销配置
            ...setMall, // 商场&商铺设置
            ...baseConfig, // 账号管理
            ...pointWarning, // 积分警告
            ...other,
            ...redeemCode, // 兑换码 （华联定制-付费会员第三方推广）
            ...massTexting, // 短信群发
            ...aiGuide, //ai智能客服
            ...financialAuditCenter, // 财务审核中心
        ],
    },
];

const router = new VueRouter({
    routes,
});

export default router;
