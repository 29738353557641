const parent = 'couponManage'; // 优惠券管理
const name = 'coupon';
const meta = { parent, name };

export default [
    {
        path: '/coupon',
        name: 'coupon',
        meta: { parent, name: 'coupon' },
        component: () => import('@/components/Coupon/Coupon'),
    },
    {
        path: '/addcoupon',
        name: 'addcoupon',
        meta,
        component: () => import('@/components/Coupon/Addcoupon'),
    },
    // 优惠券详情
    {
        path: '/coupondetail',
        name: 'coupondetail',
        meta: { parent, name: 'coupon' },
        component: () => import('@/components/Coupon/Coupondetail'),
    },
    // 优惠券审核详情（保利）
    {
        path: '/auditShotDetail',
        name: 'auditShotDetail',
        meta: { parent, name: 'coupon' },
        component: () => import('@/components/Coupon/Coupondetail'),
    },
    // 优惠券数据报表voucherreport
    {
        path: '/couponreport',
        name: 'couponreport',
        meta,
        component: () => import('@/components/Coupon/CouponReport'),
    },
    // 有价券优惠券数据报表
    {
        path: '/voucherreport',
        name: 'voucherreport',
        meta,
        component: () => import('@/components/Coupon/VoucherReport'),
    },
    {
        path: '/valucoupondetail',
        name: 'valucoupondetail',
        meta,
        component: () => import('@/components/Coupon/ValuCouponDetail'),
    },
    //有价券审核详情(免费券列表入口）
    {
        path: "/valueAuditShotDetail",
        name: "valueAuditShotDetail",
        meta,
        component: () => import("@/components/ValuableCoupon/ValuableCouponDetail"),
    },
    // 优惠券领用情况
    {
        path: '/couponreceive',
        name: 'couponreceive',
        meta,
        component: () => import('@/components/Coupon/Couponreceive'),
    },
    // 优惠券数据
    {
        path: '/datadetail',
        name: 'datadetail',
        meta,
        component: () => import('@/components/Coupon/DataDetail'),
    },
    // 优惠券数据
    {
        path: '/coupondata',
        name: 'coupondata',
        meta,
        component: () => import('@/components/Coupon/CouponData'),
    },
    {
        path: '/wxcoupon',
        name: 'wxcoupon',
        meta: { parent, name: 'name' },
        component: () => import('@/views/wxCoupon'),
    },
    {
        path: '/ysfcoupon',
        name: 'ysfCoupon',
        meta: { parent, name: 'name' },
        component: () => import('@/views/ysfCoupon'),
    },
    // 第三方卡券
    {
        path: '/thirdPartyCoupons',
        name: 'thirdPartyCoupons',
        meta: { parent, name: 'name' },
        component: () => import('@/views/thirdPartyCoupons'),
    },
    {
        path: '/addThirdCoupons',
        name: 'addThirdCoupons',
        meta: { parent, name: 'name' },
        component: () => import('@/views/thirdPartyCoupons/AddThirdCoupons'),
    },
    {
        path: '/thirdCouponDetail',
        name: 'thirdCouponDetail',
        meta: { parent, name: 'name' },
        component: () => import('@/views/thirdPartyCoupons/thirdCouponDetail'),
    },
];
